import { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { BadgeAr, Envelope, HeadsetVr, PatchPlusFill, PersonWorkspace, Phone, Whatsapp } from "react-bootstrap-icons";
import YouTube from "react-youtube";
import Translate from "../components/translator";
import { PROJECTS, TAGS } from "./ProjectData";

export default function SorgardXR() {
    const [filter, setFilter] = useState("vr")
    const [projects, setProjects] = useState(PROJECTS)

    useEffect(() => {
        LoadProjects("vr");
    }, [])


    function LoadProjects(key) {
        setFilter(key);
        setProjects(PROJECTS.filter(project => project.props.tags.includes(key)));
    }

    return (
        <div className="bg-white text-center">
            <br />
            <img id="xr_logo" height='220px' src="img/logo_sorgard_xr.png" alt="Logotipo de Sorgard XR. Equipo desarrollador de experiencias inmersivas como realidad aumentada, virtual, espacios 360, aplicaciones, etc." />
            <Tabs id="soluciones-xr" activeKey={filter} className="navbar-xr" onSelect={LoadProjects}>
                <Tab eventKey={TAGS.vr.key} title={<><HeadsetVr /> {Translate("realidad virtual", "virtual reality")}</>}></Tab>
                <Tab eventKey={TAGS.ar.key} title={<><BadgeAr /> {Translate("realidad aumentada", "augmented reality")}</>}></Tab>
                <Tab eventKey={TAGS.sim.key} title={<><PersonWorkspace /> {Translate("simuladores", "simulators")}</>}></Tab>
                <Tab eventKey={TAGS.apps.key} title={<><Phone /> {Translate("aplicaciones", "apps")}</>}></Tab>
                <Tab eventKey={TAGS.others.key} title={<><PatchPlusFill /> {Translate("otros", "misc")}</>}></Tab>
            </Tabs>

            <br />
            {projects}
            <Container className="justified">
                {TAGS[filter].content}
                <h1>{Translate("Haz realidad tu proyecto", "Make your project come true")}</h1>
                <p>{Translate("Mira nuestro reel para ver los proyectos que hemos desarrollado.", "Watch our reel video to see a brief look of our projects.")}</p>
                <YouTube videoId="xIa6ldauoco" style={{ maxWidth: '640px', margin: 'auto' }} opts={{ width: '100%', height: '390px' }} />
                <hr />
                <p>{Translate("Puedes contactarnos mediante las siguientes vías:", "You can contact us via:")}</p>
                <a href="mailto:contact@sorgardteam.com"><Envelope /> contact@sorgardteam.com</a><br />
                <Whatsapp /> +56 9 75852900 <br />
                <Whatsapp /> +56 9 77908750
            </Container>
            <br />
        </div>
    );
}