import './App.scss';
import { Image, Navbar, Nav, Container } from 'react-bootstrap';
import { CCircle, Envelope, EnvelopeFill, GeoAltFill, HouseFill, Instagram, Linkedin, Youtube } from 'react-bootstrap-icons';
import { Link, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import SorgardXR from './pages/SorgardXR';
import SorgardGames from './pages/SorgardGames';
import Jobs from './pages/Jobs';
import News from './pages/News';
import Translate from './components/translator';

function App() {
  function Header() {
    return (
      <Navbar bg="light" expand="md lg" >
        <Container fluid >
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Brand className='d-block d-sm-none'><img src='img/logo_sorgard_team.png' height='45px' alt='Logo Sorgard Team' /></Navbar.Brand>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: '80vh' }}
              navbarScroll
            >
              <Nav.Link href='/'><HouseFill /><span className='d-lg-none ml-2'>{Translate(' principal', ' home')}</span></Nav.Link>
              {/* <NavLink>noticias</NavLink> */}
              <Nav.Link active={window.location.href.includes("games")} href={"/games"}>{Translate('juegos', 'games')}</Nav.Link>
              <Nav.Link active={window.location.href.includes("xr")} href={"/xr"}>xr</Nav.Link>
              <Nav.Link active={window.location.href.includes("news")} href={'/news'}>{Translate('noticias', 'news')}</Nav.Link>
              <Nav.Link active={window.location.href.includes("jobs")} href={'/jobs'}>{Translate('trabajo', 'jobs')}</Nav.Link>
              <Nav.Link href="mailto:contact@sorgardteam.com"><EnvelopeFill /></Nav.Link>
              <Nav.Link href="https://www.youtube.com/channel/UCsyIHOyB8Sa5n_Idl9S4vyw" target="_blank"><Youtube /><span className='d-md-none'> YouTube</span></Nav.Link>
              <Nav.Link href="https://www.linkedin.com/company/42859536/admin/" target="_blank"><Linkedin /><span className='d-md-none'> LinkedIn</span></Nav.Link>
              <Nav.Link href="https://www.instagram.com/sorgard.team/" target="_blank"><Instagram /><span className='d-md-none'> Instagram</span></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  function Footer() {
    return (<footer>
      <a href='#'>{Translate("Política de Privacidad", "Privacy Policy")}</a>{" | "}
      <a href='http://shorturl.at/bqE57' target="_blank">{Translate("Código de Ética", "Ethics")}</a>{" | "}
      <a href='http://shorturl.at/ikpPT' target="_blank">{Translate("Política de Sostenibilidad", "Sustainability Ploicy")}</a>{" | "}
      <a href='http://shorturl.at/vFQUY' target="_blank">{Translate("Política de Derechos Humanos", "Human Rights Policy")}</a>
      <br />
      <GeoAltFill /> Talca, Chile | <EnvelopeFill /> contact@sorgardteam.com
      <br />
      Sorgard Team SpA <CCircle /> {new Date().getFullYear()}
    </footer>);
  }


  return (
    <>
      <div className='logo d-none d-sm-none d-md-flex'>
        <Link to='/'>
          <Image src="img/logo_sorgard_team.png" />
        </Link>
      </div>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/xr' element={<SorgardXR />} />
        <Route path='/games' element={<SorgardGames />} />
        <Route path='/jobs' element={<Jobs />} />
        <Route path='/news' element={<News />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
