import { Carousel, Image, Col, Row, Container, Badge, Card, OverlayTrigger, Popover } from "react-bootstrap";
import Translate from "../components/translator";
import { placeholder } from "./Home";

export default function SorgardGames() {


    function GameCard({ name, platforms, img, description }) {
        return (
            <Card className="game_card" style={{ width: '280px', marginBottom: '16px' }}>
                <Card.Img height={'220px'} style={{ objectFit: 'cover' }} src={img} />
                <Card.Body>
                    <Card.Title >{name}</Card.Title>
                    <Card.Text>{description}</Card.Text>
                    <Card.Text>{platforms}</Card.Text>
                </Card.Body>
            </Card>
        );
    }

    return (
        <>
            <div id="games_row" className="games_banner d-flex flex-wrap align-items-center justify-content-center" style={{ minHeight: '360px', padding: '20px' }}>
                <h1 className="text-white" style={{ fontSize: '7vw' }}>{Translate('¡nuestros juegos!', 'our games!')}</h1>
                <img height='100%' src="img/logo_games_blanco.png" />
            </div>
            <br />
            <Container className="m-auto text-center">
                <Badge className="game_type"><h2>{Translate('originales', 'originals')}</h2></Badge>
                <div className="d-flex flex-wrap justify-content-center m-3 gap-5">
                    <GameCard name="Resquack" img={"https://img.itch.zone/aW1nLzMyOTYwNjQucG5n/315x250%23c/9kYr1K.png"}
                        description={Translate("Juega como un pequeño pato que quiere cruzar la calle.", "Play as a duck that needs to cross the street!")}
                        platforms={[<a href="https://sorgardteam.itch.io/resquack" target="_blank"><img height={'28px'} src="https://static.itch.io/images/itchio-textless-black.svg"></img></a>]} />
                    <GameCard name="Havoc in the Dungeon" img={"https://img.itch.zone/aW1nLzQwNTU4NTcucG5n/315x250%23c/ENTrT3.png"}
                        description={Translate("Explora un calabozo infinito con misterios alucinantes.", "Explore a misterious and infinite dungeon.")}
                        platforms={[<a href="https://stivarts.itch.io/havoc-in-the-dungeon" target="_blank"><img height={'28px'} src="https://static.itch.io/images/itchio-textless-black.svg"></img></a>]} />
                </div>
                <Badge className="game_type"><h2>{Translate('colaboraciones', 'collabs')}</h2></Badge>
                <div className="d-flex flex-wrap justify-content-center m-3">
                    <h3 className="text-white">{Translate('próximamente...', 'coming soon...')}</h3>
                </div>
            </Container>
            <br />
        </>);
}