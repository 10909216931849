import { useEffect, useState } from "react";
import { Carousel, Container, Image, Row, Col, CarouselItem, Button } from "react-bootstrap";
import { Envelope, Linkedin, Mailbox, Mailbox2 } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Translate from "../components/translator";
export const placeholder = "https://images.ecestaticos.com/XpHlnlxZu162wj1Gpt87N8xcW8o=/0x16:987x534/600x315/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2F62c%2Fe5d%2F314%2F62ce5d3141c0b670144a692b7f6a21fa.jpg";
export default function Home() {

    const [membersList, setMembersList] = useState();

    useEffect(() => {
        const members = [
            <TeamMember name='William Benítez' role={Translate('CEO • Productor', 'CEO • Project Manager')} email='william@sorgardteam.com' data='Jugando: Hogwarts Legacy' image="/img/team_pictures/william.jpg" linkedIn='https://www.linkedin.com/in/willmbn/' />,
            <TeamMember name='Jaime Arancibia' role={Translate('COO • Programador', 'COO • Lead Programmer')} email='jaime@sorgardteam.com' data='Jugando: LEGO Star Wars' image="/img/team_pictures/jaime.jpg" linkedIn="https://www.linkedin.com/in/jaimearansoto/" />,
            <TeamMember name='Iván Poblete' role={Translate('Programador • Artista 3D', 'Programmer • 3D Artist')} email='ivan@sorgardteam.com' data='Jugando: Resident Evil 4' image="/img/team_pictures/ivan.jpg" linkedIn="https://www.linkedin.com/in/ivan-poblete-manriquez-583279134/" />,
            <TeamMember name='Elio Valenzuela' role={Translate('CTO • Programador', 'CTO • Programmer')} email='elio@sorgardteam.com' data='Jugando: Pokémon Violet' image="/img/team_pictures/elio.jpg" linkedIn="https://www.linkedin.com/in/elio-valenzuela-mu%C3%B1oz-aa6792162/" />,
            <TeamMember name='Ignacio Gajardo' role={Translate('Programador • Músico', 'Programmer • Musician')} email='ignacio@sorgardteam.com' data='Jugando: Pokémon Scarlet' image="/img/team_pictures/ignacio.jpg" linkedIn="http://www.linkedin.com/in/ignacio-gajardo-ortiz-2438851a9/" />,
            <TeamMember name='Joaquín Pavez' role={Translate('Programador', 'Programmer')} email='joaquin@sorgardteam.com' data='Jugando: Pokémon Diamante Brillante' image="/img/team_pictures/joaquin.jpg" linkedIn="https://www.linkedin.com/in/joaqu%C3%ADn-pavez-sch%C3%A4fer-18532b1b2/" />,
            <TeamMember name='Wanda' role={Translate('Ladradora', 'Barker')} email='wanda@sorgardteam.com' data='Jugando: Pelota' image="/img/team_pictures/wanda.png" />
        ]
        members.sort((a, b) => 0.5 - Math.random());
        setMembersList(members);
    }, [])


    function TeamMember({ name, role, email, data, image, linkedIn }) {
        return (
            <div class="team_member">
                <Image src={image} roundedCircle />
                <div>
                    <b>{name}</b>
                    <br />
                    <span>{role}</span>
                    <br />
                    <small><Envelope /> {email}</small>
                    <br />
                    <p>{Translate(data, '')}</p>
                </div>
                {
                    <Button href={linkedIn}>{linkedIn ? <Linkedin /> : "🐾"}</Button>}
            </div>
        );
    }

    return (
        <>
            <Carousel className="home_carousel" style={{ height: '360px' }}>
                <Carousel.Item ><img height='360px' src={"img/banner_1.webp"} /></Carousel.Item>
                <Carousel.Item ><img height='360px' src={"img/banner_2.webp"} /></Carousel.Item>
            </Carousel>
            <div className="home_content">
                <div className="content_row" id="xr_row">
                    {/* <img className="d-block d-sm-none" width='100px' src="img/logo_xr_blanco.png" alt="Logotipo blanco de Sorgard XR, división de desarrollo de experiencias inmersivas de Sorgard Team." /> */}
                    <div style={{ width: '300px', height: '280px', position: 'relative' }}>
                            <img width='90%' src="/img/home/vr-headset.webp" style={{ animation: 'floating 5s ease-in-out infinite', position: 'absolute', left: '0', bottom: '0' }} alt="Headset de un dispositivo de realidad virtual" />
                        <img width='50%' src="/img/home/smartphone.webp" style={{ animation: 'floating-reverse 4s ease-in-out infinite', position: 'absolute', right: '0' }} alt="Smartphone genérico" />
                    </div>
                    {/* <Carousel>
                        <CarouselItem><img src={"/img/xr_projects/bioinformatica_virtual_card.jpg"} /></CarouselItem>
                        <CarouselItem><img src={"/img/xr_projects/sim_industrial_1.png"} /></CarouselItem>
                        <CarouselItem><img src={"/img/xr_projects/sim_seguridad_1.png"} alt="Simulador de Seguridad Industrial, desarrollado por Sorgard Team." /></CarouselItem>
                        <CarouselItem><img src={"/img/xr_projects/visualizador_casas_card.jpg"} alt="Visualizador de casas O'Higgins desarrollado por Sorgard Team." /></CarouselItem>
                    </Carousel> */}
                    <div style={{ maxWidth: 'min(690px,90vw)' }} className="text-white text-start">
                        <h1>{Translate(
                            <>experiencias inmersivas <span className="fs-2">y</span> <span className="text-outline">software interactivo</span></>
                            ,
                            <>inmersive experiences <span className="fs-2">&</span> <span className="text-outline">interactive software</span></>
                        )}</h1>
                        <p>{Translate("Te ayudamos a hacer realidad tus proyectos, en distintos enfoques, con un ciclo de desarrollo completo.", "We help you to bring your projects to reality. Providing a full-cycle development.")}</p>
                        <Link to="/xr"><Button className="btn-secondary">{Translate("Ver más", "More info about XR")}</Button></Link>
                    </div>
                </div>
                <div className="content_row" id="games_row">
                    <div style={{ maxWidth: 'min(690px,90vw)' }} className="text-white text-end">
                        <h1>{Translate(
                            <>desarrollo de <span className="text-outline">videojuegos</span></>,
                            <>videogames <span className="text-outline">development</span></>
                        )}</h1>
                        <p>{Translate("Creamos mundos y experiencias únicas en distintas plataformas explorando diversos estilos. ¡Prepárate para las sorpresas que tendremos pronto para ti!",
                            "We create worlds and unique experiences on multiple platforms exploring diverse styles. Stay tuned for the surprises we have prepared for you!")}</p>
                        <Link to="/games"><Button className="btn-secondary">{Translate("Ver más", "More info about Videogames")}</Button></Link>
                    </div>
                    <div style={{ width: '300px', height: '280px', position: 'relative' }}>
                        <img width='70%' src="/img/home/controller.webp" style={{ animation: 'floating 5s ease-in-out infinite', position: 'absolute', left: '0', top: '0' }} alt="Modelo 3D de un mando de videojuegos"/>
                        <img width='60%' src="/img/home/fox.webp" style={{ animation: 'floating-reverse 4s ease-in-out infinite', position: 'absolute', right: '0', bottom: '0' }} alt="Lindo modelo 3D de un zorro"/>
                    </div>
                    {/* <Carousel>
                        <CarouselItem><img height={'300px'} src="https://img.itch.zone/aW1nLzUyMjIyODEucG5n/original/xEy8zO.png" /></CarouselItem>
                        <CarouselItem><img height={'300px'} src="https://i.ytimg.com/vi/hJXHLl-l5JI/maxresdefault.jpg" /></CarouselItem>
                    </Carousel> */}
                    {/* <img width='100px' className="d-block d-sm-none" src="img/logo_games_blanco.png" alt="Logotipo blanco de Sorgard Games, división de desarrollo de videojuegos de Sorgard Team." /> */}
                </div>
            </div>
            <br />
            <Container id="home_lower_content">
                <h1>{Translate("sobre nosotros", "about us")}</h1>
                <p>{Translate(<>Somos un equipo multidisciplinar ubicado en Talca, Chile. Desarrollamos productos tecnológicos para diversas plataformas.
                    Creamos experiencias interactivas para campos como la educación, minería, ciencia, salubridad, conducción y más.
                    También somos parte de la industria creativa, plasmando las ideas en proyectos y los proyectos en realidades.</>,
                    <>We are a multidisciplinary team, we develop technological products for various platforms.
                        We create interactive experiences for fields like education, mining, science, healthcare, driving and more.
                        We are also part of the creative industry, turning ideas into projects and projects into reality.</>)}</p>
                <div class="team_members">
                    {membersList}
                </div>
                <br /><br />
                <h1>{Translate("nuestros colaboradores", "our partners")}</h1>
                <br />
                <div class="partners" >
                    <a href="https://www.mim.cl">
                        <Image src="img/colaboradores/mim.png" alt="Logotipo del Museo Interactivo Mirador (MIM) blanco" />
                    </a>
                    <a href="http://www.casasohiggins.cl">
                        <Image src="img/colaboradores/casas_ohiggins.png" alt="Logotipo de Casas O'Higgins" />
                    </a>
                    <a href="http://www.dosbarbas.cl">
                        <Image src="img/colaboradores/dos_barbas.png" alt="Logotipo de productora Dos Barbas" />
                    </a>
                    <a href="http://www.pi.cl">
                        <Image src="img/colaboradores/pi.webp" alt="Logotipo de Proyección de Ideas Rapallo (Pi)" />
                    </a>
                    <a href="http://www.prochile.cl">
                        <Image src="img/colaboradores/prochile.png" alt="Logotipo de ProChile" />
                    </a>
                    <a href="https://www.linkedin.com/company/sciremotion/about/">
                        <Image src="img/colaboradores/logo-sciremotion.png" alt="Logotipo de Sciremotion" />
                    </a>
                </div>
            </Container>
            <br />
        </>
    );
}