import detectBrowserLanguage from "detect-browser-language";
import { createRef, useState } from "react";
import { Accordion, Alert, Badge, Button, Container, Form, FormGroup, Modal } from "react-bootstrap";
import { GeoAltFill } from "react-bootstrap-icons";
import Translate from "../components/translator";

export default function Jobs() {

    const JOBS = [
        /*<JobItem
            name={"Programador Back-end"}
            description={"Buscamos talentos que trabajen en sistemas web en el lado de back-end. Requerimos conocimiento en Express, Axios, Node.js."}
            extraData={"Esta es una oferta de trabajo falsa."}
        />*/
    ]

    const [mailBody, setMailBody] = useState(<></>);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function JobItem({ name, description, remote = true, inplace = true, extraData }) {
        return (
            <Accordion.Item eventKey={name}>
                <Accordion.Header>{name}</Accordion.Header>
                <Accordion.Body className="text-start">
                    <GeoAltFill /> {Translate("Tipo de trabajo:", "Job modularity:")} {remote && <Badge>{Translate("remoto", "at home")}</Badge>} {inplace && <Badge>{Translate("presencial", "face-to-face")}</Badge>}
                    <hr />
                    {description}
                    {extraData && <><hr /><Alert>{extraData}</Alert></>}
                </Accordion.Body>
            </Accordion.Item>
        );
    }
    return (
        <>
            <div className="jobs-banner text-white text-center d-flex justify-content-center align-items-center" style={{ height: '220px', background: 'black', marginBottom: '20px' }}>
                <h1>{Translate("¡trabaja con nosotros!", "work with us!")}</h1>
            </div>
            <Container className="text-white text-center" style={{ maxWidth: '860px', margin: 'auto' }}>
                <h2>{Translate("Vacantes", "Jobs")}</h2>
                <Accordion >
                    {JOBS.length > 0 ? JOBS :
                        <Alert>{Translate("Por ahora no tenemos ofertas de trabajo disponibles. Sin embargo, puedes enviarnos tu portafolio o buscar recomendaciones vía correo: contact@sorgardteam.com",
                            "Currently, we are not offering jobs. You can stll send us your portfolio for consideration to contact@sorgardteam.com.")}</Alert>}
                </Accordion>
                {detectBrowserLanguage().includes("es") &&
                    <>
                        <h2>¿Buscas realizar tu práctica profesional?</h2>
                        <iframe style={{ height: '800px', width: '100%', border: 'none' }} src='https://forms.zohopublic.com/sorgardteam/form/PostulacinaPrcticaProfesional/formperma/HglBs7sriRV8ytM6h8Atu62spnvNAl0Evcm3QxzW-wc' />
                    </>}
            </Container >
            <br />
        </>
    );
}