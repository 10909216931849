import { Badge, Button, Carousel, Container } from "react-bootstrap";
import Translate from "../components/translator";
import { placeholder } from "./Home";
export const TAGS = {
    vr: {
        name: Translate("realidad virtual", "virtual reality"), content: Translate(<><h1>¿Qué es la realidad virtual?</h1><p>La realidad virtual es una tecnología que permite a los usuarios
            sumergirse en un mundo completamente generado por computadora, mediante el uso de un casco con pantallas y auriculares. Esto les permite
            experimentar una sensación de presencia en un ambiente completamente diferente al mundo real, como si estuvieran dentro de un videojuego o
            visitando un lugar histórico. Es como tener una experiencia de viaje sin salir de casa. ¡Imagina poder explorar el fondo del océano o volar
            sobre una ciudad futurista sin moverte de tu sillón! La realidad virtual también se utiliza en campos educativos y terapéuticos, como la
            formación de médicos y la rehabilitación de pacientes con discapacidades.</p></>,
            <><h1>What is virtual reality?</h1><p>Virtual reality is a technology that allows users to
                immerse in a completely computer-generated world, through the use of a headset. This allows them to experience a sense of presence in an
                environment completely different from the real world, as if they were inside a video game or
                visiting a historical place. It's like having a travel experience without leaving home. Imagine being able to explore the bottom of the ocean or fly
                over a futuristic city without leaving your home! Virtual reality is also used in educational and therapeutic fields, such as
                training of doctors and the rehabilitation of patients with disabilities.</p></>
        ), key: "vr"
    },
    ar: {
        name: Translate("realidad aumentada", "augmented reality"), content: Translate(<><h1>¿Qué es la realidad aumentada?</h1><p>La realidad aumentada es como una mezcla entre el mundo real
            y el mundo virtual. En lugar de sumergirte completamente en un mundo generado por computadora, la realidad aumentada te permite ver elementos
            virtuales superpuestos en el mundo real. Imagine mirar a través de su teléfono y ver un dinosaurio caminando por la calle o un juego virtual
            que se juega en su sala de estar. Esto puede ser increíblemente emocionante y divertido.</p>
            <p>La realidad aumentada también tiene un gran potencial educativo, como en la medicina, arquitectura, arte, diseño y en la educación en general.
                Por ejemplo, los estudiantes pueden ver modelos tridimensionales de sistemas del cuerpo humano, los arquitectos pueden visualizar cómo será un
                edificio antes de construirlo, los artistas pueden crear proyectos interactivos y los estudiantes pueden aprender de una manera
                más inmersiva y atractiva.</p></>,
            <><h1>What is augmented reality?</h1><p>Augmented reality is like a mix between the real world
                and the virtual world. Instead of being completely immersed in a computer generated world, augmented reality allows you to see elements
                virtual superimposed on the real world. Imagine looking through your phone and seeing a dinosaur walking down the street or a virtual game
                which is played in your living room. This can be incredibly exciting and fun.</p>
                <p>Augmented reality also has great educational potential, such as in medicine, architecture, art, design and education in general.
                    For example, students can view three-dimensional models of human body systems, architects can visualize what a
                    building before it is built, artists can create interactive projects and students can learn in a way
                    more immersive and attractive.</p></>), key: "ar"
    },
    sim: {
        name: Translate("simuladores", "simulators"), content: Translate(<><h1>¿En qué consisten los simuladores?</h1><p>Los software de simulación interactiva son programas que te
            permiten experimentar con un ambiente o sistema de manera virtual, de tal forma que puedas interactuar con él y ver cómo se comporta.
            Es como tener tu propio laboratorio virtual donde puedes probar cosas sin necesidad de preocuparte por las consecuencias.</p>
            <p>Por ejemplo, en un software de simulación de vuelo, puedes controlar un avión y experimentar cómo se siente volar en condiciones
                diferentes, o en un simulador de conducción puedes manejar un automóvil en una pista de carreras y aprender cómo tomar las curvas
                de manera correcta.</p>
            <p>Los softwares de simulación interactiva se utilizan en una variedad de campos, desde la formación de pilotos y conductores hasta
                la investigación en ingeniería y arquitectura. ¡Es una manera emocionante y segura de aprender y experimentar!</p></>,
            <><h1>What are simulators?</h1><p>Interactive simulation software are programs that allow you to experiment with an environment or system
                in such a way that you can interact with it and see how it behaves.
                It's like having your own virtual lab where you can test things without worrying about the consequences.</p>
                <p>For example, in flight simulation software, you can control an airplane and experience how it feels to fly in extreme conditions,
                    or in a driving simulator you can drive a car on a racing track and learn how to take the turns correctly.</p>
                <p>Interactive simulation software is used in a variety of fields, from pilot and driver training to
                    research in engineering and architecture. It's an exciting and safe way to learn and experiment!</p></>), key: "sim"
    },
    apps: {
        name: Translate("aplicaciones", "apps"), content: Translate(<><h1>¿Qué son las aplicaciones?</h1>
            <p>Las aplicaciones móviles son programas pequeños que puedes descargar en tu teléfono inteligente o tablet. Son como mini
                programas de computadora que te permiten hacer una variedad de cosas, desde jugar juegos hasta hacer compras en línea,
                mantenerte organizado, escuchar música, ver videos, entre muchas otras cosas. Es como tener una pequeña tienda de aplicaciones en tu bolsillo.
                Hay una gran variedad de aplicaciones móviles para elegir, desde las populares como Facebook y Instagram, hasta las más específicas para ciertos
                campos como la medicina, la agricultura, el transporte, entre muchas otras.
                En resumen, las aplicaciones móviles son como tener un superpoder en tu bolsillo, te permiten hacer cosas increíbles y estar conectado
                con el mundo en todo momento.</p></>,
            <><h1>What is an app?</h1>
                <p>Mobile apps are small programs that you download to your smartphone or tablet. they are like mini
                    computer programs that allow you to do a variety of things, from playing games to shopping online,
                    stay organized, listen to music, watch videos, among many other things. It's like having a small app store in your pocket.
                    There are a wide variety of mobile apps to choose from, from popular ones like Facebook and Instagram, to more specific to certain
                    fields such as medicine, agriculture, transportation, among many others.
                    Long story short, mobile apps are like having a superpower in your pocket, they allow you to do amazing things and stay connected
                    with the world at all times.</p></>), key: "apps"
    },
    others: {
        name: Translate("otros", "miscellaneous"), content: <></>, key: "others"
    }
}

function Project({ name, tags, description, images }) {
    return (
        <div className="bg-light d-flex flex-wrap text-start gap-3 m-auto rounded-5 p-3 mb-4 justify-content-center project">
            <div style={{ maxWidth: '680px' }}>
                <h1>{name}</h1>
                {
                    tags ? tags.map((tag) => <Badge>{TAGS[tag].name}</Badge>) : ""
                }
                <p>{description}</p>
            </div>
            <Carousel className="xr_project_carousel" >
                {
                    images ? images.map((image) =>
                        <Carousel.Item >
                            <img style={{ objectFit: 'cover' }} src={image} />
                        </Carousel.Item>
                    ) : ""}
            </Carousel>
        </div>
    );
}
// export const PROJECTS ="Hola wena";
export const PROJECTS = [
    <Project
        name="SmartDriVR"
        tags={[TAGS.vr.key, TAGS.sim.key]}
        description={Translate(<>Software de simulación de conducción en un entorno de realidad virtual, administrado por evaluadores mediante un sistema web.
            Varios recorridos en diferentes condiciones viales, donde el conductor deberá reconocer peligros y respetar las reglas del tránsito,
            siendo monitoreado constantemente.</>,
            <>Driving simulation software in a virtual reality environment, managed by testers through a web system.
                Several routes in different road conditions, where the driver must recognize dangers and respect traffic rules,
                being constantly monitored.</>)}
        images={["https://simumak.com/wp-content/uploads/2021/05/Simulador-de-coche-para-el-estudio-cientifico-de-la-conduccion-de-Simumak-scaled.jpg"]}
    />,
    <Project
        name={Translate("Bioinformática virtual", "Virtual Bioinformatic")}
        tags={[TAGS.ar.key, TAGS.apps.key]}
        description={<>
            {Translate(<p>
                Aplicación desarrollada en el marco de la feria de bioinformática virtual, en la cual los usuarios pueden resolver una quest para
                descubrir información sobre moléculas. También utiliza el concepto "geocatching" en el que los jugadores deberán encontrar elementos que existen
                físicamente para recolectar items dentro de la aplicación y progresar.
            </p>, <p>
                Application developed within the framework of the virtual bioinformatics fair, in which users can solve a quest to
                discover information about molecules. It also uses the concept "geocatching" in which players must find items that exist
                physically to collect items within the app and progress.
            </p>)}
            <hr />
            <Button href="https://bioinformaticavirtual.utalca.cl/actividad3.html" target="_blank">{Translate("Ver sitio", "Go to site")}</Button>

        </>}
        images={[
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6Ej_QMO0qqqurrT5NMMIp2fgxQb5FNdBCwcZ-LNgUYXy3aYkf5uilAwXnGK27e9AXZkg&usqp=CAU"
        ]}

    />,
    <Project
        name={Translate("Simulador industrial", "Industrial simulator")}
        tags={[TAGS.sim.key]}
        description={<>
            {Translate(<p>
                Serie de programas interactivos aplicados como módulos en establecimientos educacionales. Permiten capacitar a los usuarios en diferentes
                aspectos de la industria minera, tales como electricidad, máquinas y herramientas, electromecánica, etc.</p>,
                <p>
                    Series of interactive programs applied as modules in educational establishments. They allow users to be trained in different
                    aspects of the mining industry, such as electricity, machines and tools, electromechanics, etc.
                </p>)}
        </>
        }
        images={["img/xr_projects/sim_industrial_1.png", "img/xr_projects/sim_industrial_2.png"]}
    />,
    <Project
        name={Translate("Simulador de seguridad", "Safety simulator")}
        tags={[TAGS.sim.key]}
        description={<>
            {Translate(<p>
                Experiencia interactiva que enseña sobre la importancia de la seguridad industrial y los procesos
                solicitados en un ambiente recreado dentro de la aplicación.
            </p>,
                <p>
                    Interactive experience that teaches about the importance of industrial safety and the processes requested
                    in an environment recreated within the application.
                </p>)}
        </>}
        images={["img/xr_projects/sim_seguridad_1.png"]}

    />,
    <Project
        name={Translate("Compuertas Lógicas AR", "Logic Gates AR")}
        tags={[TAGS.ar.key, TAGS.apps.key]}
        description={<>
            {Translate(<p>
                Aplicación orientada al aprendizaje sobre compuertas lógicas utilizando tarjetas que representan conductores, estados y señales.
            </p>,
                <p>
                    Application oriented to learning about logic gates using phyisical cards that represent conductors, states and signals.
                </p>)}
        </>}
        images={["http://guiademecatronica.com/wp-content/uploads/2019/04/compuertas-logicas.png"]}
    />,
    <Project
        name={Translate("Defiéndete de los virus", "Virus Defense")}
        tags={[TAGS.others.key]}
        description={<>
            {Translate(
                <p>
                    Experiencia que utiliza la tecnología <i>Kinect</i> para mover al personaje controlado por el usuario en un entorno real utilizando una
                    pantalla gigante dentro de una feria. Realizamos la tercerización de este proyecto producido por <a href="http://pi.cl" target="_blank">Pi.</a>
                </p>,
                <p>
                    Experience that uses <i>Kinect</i> technology to move the user-controlled character in a real environment using a
                    giant screen inside a fair. We outsourced this project produced by <a href="http://pi.cl" target="_blank">Pi.</a>
                </p>
            )}
            <hr />
            <Button href="https://twitter.com/ministeriosalud/status/1470842063881216000?t=8UFpcWKVTGJDPS3LG0LvCw&s=08 " target="_blank">{Translate("Ver video de la feria", "Watch video")}</Button>{" "}
            <Button href="https://mim.cl/microvida-la-inedita-sala-del-mim-inspirada-en-el-covid-19/ " target="_blank">{Translate("Ver noticia de la feria", "Go to news site")}</Button>

        </>}
        images={[
            "https://mim.cl/content/uploads/neutralizados.jpg",
        ]}
    />,
    <Project
        name={Translate("Visualizador de casas", "Houses Visualizer")}
        tags={[TAGS.vr.key]}
        description={Translate(
            <>Software inmersivo que permite a los usuarios previsualizar el entorno de una casa prefabricada a escala real, pudiendo editar ciertas preferencias entre un amplio
                set de casas y ambientes.</>,
            <>Immersive software that allows users to preview the environment of a prefab house at real scale, being able to edit certain preferences among a wide
                set of houses and environments.</>
        )}
        images={[
            "https://casasohiggins.cl/wp-content/uploads/2021/11/Imagen61-1024x576.jpg",
            "img/xr_projects/visualizador_casas_card.jpg"
        ]}

    />
]