import { Button, Card } from "react-bootstrap";
import Translate from "../components/translator";

const VER_NOTICIA = Translate("Ver noticia", "Go to site")

export default function News() {
    return (
        <div className="m-5 text-white text-center">
            <h1>{Translate('noticias', 'news')}</h1>
            <p>{Translate('Aquí podrás ver noticias donde somos referenciados en diferentes medios.',
                'Explore media where we have been referenced.')}</p>
            <hr />
            <div className="text-dark news d-flex flex-wrap gap-5 align-items-baseline justify-content-center">
                <Card>
                    <Card.Img src="https://www.utalca.cl/content/uploads/2021/10/Universidad-de-Talca-2021-10-19T162529.780.png" />
                    <Card.Header>Egresados de Ingeniería en Desarrollo de Videojuegos y Realidad Virtual se posicionan en la industria creativa</Card.Header>
                    <Card.Subtitle>UTalca Noticias</Card.Subtitle>
                    <Button href="https://www.utalca.cl/noticias/egresados-de-ingenieria-en-desarrollo-de-videojuegos-y-realidad-virtual-se-posicionan-en-la-industria-creativa/">{VER_NOTICIA}</Button>
                </Card>
                <Card>
                    <Card.Img src="https://elcentro.cl/wp-content/uploads/2022/08/sorgard-team-en-gamescom.jpg" alt="William Benítez y Jaime Arancibia de Sorgard Team exponiendo en la feria Gamescom 2022." />
                    <Card.Header>Empresa talquina de desarrollo de videojuegos se presentó en la Gamescom, la feria electrónica más importante de Europa</Card.Header>
                    <Card.Subtitle>El Centro</Card.Subtitle>
                    <Button href="https://elcentro.cl/2022/08/29/empresa-talquina-de-desarrollo-de-videojuegos-se-presento-en-la-gamescom-la-feria-electronica-mas-importante-de-europa/">{VER_NOTICIA}</Button>
                </Card>
                <Card>
                    <Card.Img src="https://acceso.prochile.cl/wp-content/uploads/2021/05/convocatoria_1080_1080_01-300x300.png" />
                    <Card.Header>Chile regresa a BIG Festival con videojuego finalista en 5 categorías y amplia delegación</Card.Header>
                    <Card.Subtitle>Pro Chile</Card.Subtitle>
                    <Button href="https://acceso.prochile.cl/noticia/chile-regresa-a-big-festival-con-videojuego-finalista-en-5-categorias-y-amplia-delegacion/">{VER_NOTICIA}</Button>
                </Card>
                <Card>
                    <Card.Img src="https://i3.ytimg.com/vi/nYazH_k2gnk/maxresdefault.jpg" alt="Jaime Arancibia, de Sorgard Team, utilizando periféricos de simulación de conducción." />
                    <Card.Header>El aporte de utalinos al desarrollo tecnológico e industria creativa del país</Card.Header>
                    <Card.Subtitle>Campus TV</Card.Subtitle>
                    <Button href="https://www.youtube.com/watch?v=nYazH_k2gnk">{Translate("Ver video", "Watch video")}</Button>
                </Card>
            </div>
        </div>
    );
}